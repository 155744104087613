import { CastRounded } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { width } from "@mui/system";
import React, { Component } from "react";
import imgBanner from "../image/banner.png";
import ImproveBanner from "../image/ImproveBanner.png";

class banner extends Component {
  render() {
    return (
      <div>
        <CardMedia
          sx={{ height: "auto", marginTop: "-65px" }}
          src={ImproveBanner}
          component="img"
          alt="banner"
        />
      </div>
    );
  }
}
export default banner;
