import React, { Component } from "react";
import Banner from "./banner";
import Experties from "./Experties";
import DigitalMarkting from "./DigitalMarkting";
import Strategy from "./Strategy";
import Footer from "./Footer";
import Portfolio from "./Portfolio";
import ScrollToTop from "../ScrollToTop";
import Scroll from "../Scroll";
import OurClient from "./OurClient";
import { connect } from "react-redux";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "../mapRedux/mapReduxProps";
class Home extends Component {
  //#region Component
  componentDidMount() {
    document.title = "Ravi Tech World";
    this.props.setActiveMenu("");
  }
  //#endregion Component

  render() {
    return (
      <div>
        <Banner />
        <Experties />
        <OurClient />
        <DigitalMarkting />
        <Portfolio />
        {/* <Strategy /> */}
        <Footer />
      </div>
    );
  }
}

export default connect(mapStatetoProps, mapSetActiveMenuToProps)(Home);
