import {
  Grid,
  Input,
  Typography,
  TextField,
  Box,
  Button,
  CardMedia,
  Stack,
} from "@mui/material";
import HoCtToastContainer from "../HOC/HoCtToastContainer";
import SendIcon from "@mui/icons-material/Send";
import CtTxt from "../CustomTool/CtTxt";
import CtMultiLineText from "../CustomTool/CtMultiLineText";
import React, { Component, useState } from "react";
import contactUsImg from "../image/contactusimg.png";
import contactUsImg1 from "../image/contactusimg1.png";
import address from "../image/address.png";
import contact from "../image/contact.png";
import mail from "../image/mail.png";
import textImg from "../image/letsgetintouch.png";
import { red } from "@mui/material/colors";
import { fetchcontactusdetail, onlineBackEndURL } from "../API/API";
import { connect } from "react-redux";
import {
  mapStatetoProps,
  mapSetActiveMenuToProps,
} from "../mapRedux/mapReduxProps";
import Scroll from "../Scroll";
import Footer from "../home/Footer";

class ContactUs extends Component {
  //#region Component
  componentDidMount() {
    this.props.setActiveMenu("ContactUs");
    document.title = "Contact Us | Ravi Tech World";
  }
  //#endregion Component
  state = {
    formData: {
      txtname: "",
      txtemail: "",
      txtmobileno: "",
      txtsubject: "",
      txtmessage: "",
    },
  };
  //#region handle
  handleOnChange = (e) => {
    this.setState({
      formData: { ...this.state.formData, [e.target.name]: e.target.value },
    });
  };
  //#endregion handle

  //#region function
  validateContactUs = () => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const isValidMobile = /^[0-9]{10}$/g;
    let result = false;
    if (this.state.formData.txtname.length <= 0) {
      this.props.toastErrorMsg("Enter your Name", "txtname");
    } else if (this.state.formData.txtemail.length <= 0) {
      this.props.toastErrorMsg("Enter Email", "txtemail");
    } else if (!isValidEmail.test(this.state.formData.txtemail)) {
      this.props.toastErrorMsg("Enter valid Email", "txtemail");
    } else if (!isValidMobile.test(this.state.formData.txtmobileno)) {
      this.props.toastErrorMsg("Enter valid Mobile No", "txtmobileno");
    } else if (this.state.formData.txtmobileno.length <= 0) {
      this.props.toastErrorMsg("Enter Mobile No", "txtmobileno");
    } else if (this.state.formData.txtsubject.length <= 0) {
      this.props.toastErrorMsg("Enter Subject", "txtsubject");
    } else if (this.state.formData.txtmessage.length <= 0) {
      this.props.toastErrorMsg("Enter Message", "txtmessage");
    } else {
      result = true;
    }
    return result;
  };

  SaveContactUs = () => {
    if (this.validateContactUs()) {
      const dataLoadStatus = false;
      this.props.notifyProcessing();
      this.setState({ dataLoadStatus }, () => {
        const reqData = {
          Op: "SaveContactUs",
          bint_srno: "",
          vac_name: this.state.formData.txtname,
          vac_email: this.state.formData.txtemail,
          vac_mobileno: this.state.formData.txtmobileno,
          vac_subject: this.state.formData.txtsubject,
          vac_message: this.state.formData.txtmessage,
        };
        fetchcontactusdetail(reqData)
          .then((res) => {
            this.props.updateProcessing(res.data.msgType, res.data.message);
            if (res.data.msgType === "success") {
              // this.getInfo();
              this.clearInfo(true);
            }
          })
          .catch((error) => {
            console.log("Unknown error occurred in SaveContactUs.", error);
          });
      });
    }
  };

  clearInfo = () => {
    const txtname = "",
      txtemail = "",
      txtmobileno = "",
      txtsubject = "",
      txtmessage = "";

    this.setState(
      {
        formData: {
          ...this.state.formData,
          txtname,
          txtemail,
          txtmobileno,
          txtsubject,
          txtmessage,
        },
      },
      () => {
        document.getElementById("txtname").focus();
      }
    );
  };

  //#endregion function

  render() {
    return (
      <div>
        <Scroll />
        <div>
          <CardMedia
            sx={{ height: "auto", marginTop: "-65px" }}
            src={contactUsImg1}
            component="img"
            alt="banner"
          />
          {/* <img
            src={contactUsImg1}
            style={{
              height: "auto",
              width: "auto",
              maxHeight: "89.9vh",
              // maxWidth: "206vh",
            }}
          ></img> */}
        </div>
        <div>
          <Grid container marginTop="80px" justifyContent={"center"}>
            <Grid item md="7.4" xs="11">
              <Typography
                marginTop="6px"
                variant="h1"
                style={{
                  color: "#1b2f47",
                  fontSize: "55px",
                }}
              >
                Get in touch <span style={{ color: "#ED3234" }}>with us</span>
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              marginTop="20px"
              justifyContent={"center"}
            >
              <Grid item md="3" ml={"auto"} mr={"10px"} xs="11">
                <Grid container direction="column" spacing={4}>
                  <Grid item>
                    <Typography
                      marginTop="6px"
                      style={{
                        color: "#7e7e7e",
                        fontSize: "17px",
                      }}
                    >
                      You can contact us with anything related to our Products
                      or Services. We'll get in touch with you as soon as
                      possible.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <CtTxt
                      id="txtname"
                      label="Name"
                      value={this.state.formData.txtname}
                      maxLength={50}
                      handleOnChange={this.handleOnChange}
                    />
                  </Grid>
                  <Grid item>
                    <CtTxt
                      id="txtemail"
                      label="E-Mail"
                      value={this.state.formData.txtemail}
                      handleOnChange={this.handleOnChange}
                      maxLength={50}
                    />
                  </Grid>
                  <Grid item>
                    <CtTxt
                      id="txtmobileno"
                      value={this.state.formData.txtmobileno}
                      label="Mobile No."
                      maxLength={10}
                      handleOnChange={this.handleOnChange}
                    />
                  </Grid>
                  <Grid item>
                    <CtTxt
                      label="Subject"
                      id="txtsubject"
                      value={this.state.formData.txtsubject}
                      maxLength={50}
                      handleOnChange={this.handleOnChange}
                    />
                  </Grid>
                  <Grid item>
                    <CtMultiLineText
                      label="Message"
                      id="txtmessage"
                      value={this.state.formData.txtmessage}
                      maxLength={200}
                      handleOnChange={this.handleOnChange}
                    />
                  </Grid>
                  <Grid item ml={"auto"} mr={"auto"} marginTop="20px">
                    <Button
                      variant="contained"
                      color="error"
                      endIcon={<SendIcon />}
                      onClick={this.SaveContactUs}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md="2" ml={"auto"} mr={"auto"}>
                <Grid container direction="column" spacing={3} marginTop="30px">
                  <Grid item>
                    <Stack direction="row" spacing={3}>
                      <img
                        src={address}
                        style={{
                          height: "40px",
                          width: "auto",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#1b2f47",
                        }}
                      >
                        AHMEDABAD ADDRESS <br />
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "200",
                            color: "#5c5c5c",
                          }}
                        >
                          A-206, Aarohi Galleria,
                          <br />
                          South Bopal, Ahmedabad
                          <br />
                          Gujarat, India.
                          <br />
                          Pincode: 380-058
                        </p>
                      </Typography>
                    </Stack>
                    <Stack direction="row" spacing={3}>
                      <img
                        src={address}
                        style={{
                          height: "40px",
                          width: "auto",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#1b2f47",
                        }}
                      >
                        BHAVNAGAR ADDRESS <br />
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "200",
                            color: "#5c5c5c",
                          }}
                        >
                          301,shobhraj complex,
                          <br />
                          3rd floor sahkari Hat circle,
                          <br />
                          Waghawadi Road,Bhavnagar
                          <br />
                          Gujarat,India.
                          <br />
                          Pincode:364-002
                        </p>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={3}>
                      <img
                        src={contact}
                        style={{
                          height: "40px",
                          width: "auto",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#1b2f47",
                        }}
                      >
                        CONTACT <br />
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "200",
                            color: "#5c5c5c",
                          }}
                        >
                          +91 8000 51 60 51 <br />
                          +91 9408 90 96 82
                        </p>
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={3}>
                      <img
                        src={mail}
                        style={{
                          height: "30px",
                          width: "auto",
                        }}
                      />
                      <Typography
                        style={{
                          fontSize: "20px",
                          fontWeight: "600",
                          color: "#1b2f47",
                        }}
                      >
                        MAIL <br />
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "200",
                            color: "#5c5c5c",
                          }}
                        >
                          Info@ravitechworld.com
                        </p>
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container padding={"30px"}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.1434912176082!2d72.46624477604!3d23.018503079176746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b75dc2923c9%3A0xea4ace8cca4d7738!2sAarohi%20galleria!5e0!3m2!1sen!2sin!4v1730719008025!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>
        </div>
        <Footer />
      </div>
    );
  }
}

export default connect(
  mapStatetoProps,
  mapSetActiveMenuToProps
)(HoCtToastContainer(ContactUs));
